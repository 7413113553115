// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import formatCurrency from '~/utils/formatCurrency';
import PaneSelectFulfillment from './PaneSelectFulfillment';
import PaneSelectAddress from './PaneSelectAddress';
import PaneSelectPayment from './PaneSelectPayment';
import PaneAddPaymentContainer from '../containers/PaneAddPaymentContainer';
import PanePlaceBid from './PanePlaceBid';
import * as BidActions from '../actions/bid';
import * as BidNewActions from '../actions/bidNew';
import * as ModalActions from '../actions/modal';
import * as ShippingQuoteBidActions from '../actions/shippingQuoteBid';
import { userAddressIdToObject } from '../utils/addressHelpers';
import {
  placeBid,
  updateBid,
  bidIncrement,
  nextBidMinimum,
  calculateAmount,
  calculateMax,
  calculateFulfillmentDefault,
  calculateCurrentPaymentMethod,
  calculateCurrentAddressId,
  validateBid,
} from '../utils/bid';
import { isShippingAvailable } from '../utils/fulfillmentTypeHelpers';
import type {
  Bid,
  BidChange,
  Item,
  User,
  Config,
  PaymentMethod,
  Address,
} from '../types';
import { setPublicMessageInStorage } from '../../shared/utils/flashMessage';

type Props = {
  bid: Bid,
  bidNew: Bid,
  item: Item,
  closeModal: (string) => void,
  updateBid: (Object) => void,
  user: User,
  config: Config,
  updateBidNew: (Object) => void,
  fetchQuote: ({ zip?: string, addressId?: string, itemId: string }) => void,
  resetQuote: () => void,
  shippingQuoteBid: Object,
  paypageId: string,
  paypageUrl: string,
  plaidEnvironment: string,
  useVantiv: boolean,
  featureFlags: Object,
};

type State = {
  currentPane: string,
  bidInFlight: boolean,
  paymentType: number,
  updates: Object,
  didUserFocusBidAmount: boolean,
  isMaxBid: boolean,
};

class ModalBiddingContent extends Component<Props, State> {
  state = {
    currentPane: this.props.bid.fulfillmentType ? 'bid' : 'fulfillment',
    bidInFlight: false,
    paymentType: 0,
    updates: {},
    didUserFocusBidAmount: false,
    isMaxBid: false,
  }

  componentWillMount() {
    const {
      updateBidNew,
      bid,
      bidNew,
      item,
      user,
      fetchQuote,
      featureFlags,
    } = this.props;
    const bidNewUpdates = {
      id: bid.id,
      amount: calculateAmount(bid, item),
      max: calculateMax(bid),
      fulfillmentType: calculateFulfillmentDefault(bid, item, user),
      isConfirmed: !!item.bidderIds.includes(user.id),
      errors: null,
      amountErrors: [],
      maxErrors: [],
      fulfillmentErrors: [],
      confirmationError: false,
      paymentMethod: calculateCurrentPaymentMethod(bid, user),
      hasPaymentDiscount: bid.hasPaymentDiscount,
      address: {
        id: calculateCurrentAddressId(bid.address, user),
      },
      bypassGiftCardBalance: bid.bypassGiftCardBalance,
    };

    updateBidNew(bidNewUpdates);

    // Pre-fetch shipping quote if ideal
    if (
      !bid.shippingQuote
      && !bidNew.shippingQuote
      && isShippingAvailable(item.fulfillmentTypes)
      && bidNewUpdates.address
      && bidNewUpdates.address.id
    ) {
      const address = userAddressIdToObject(bidNewUpdates.address.id, user);
      if (address && !this.isNotInternationalShippingEligible(address, item, bidNewUpdates)) {
        if (!address.international) {
          fetchQuote({ zip: address.zip, itemId: item.id });
        } else if (featureFlags.internationalShippingQuotes) {
          fetchQuote({ addressId: address.id, itemId: item.id });
        }
      }
    }
  }

  componentDidMount() {
    if (window.analytics) {
      const {
        item,
        bid,
        user,
        config,
      } = this.props;

      window.analytics.track('Item Bid Started', {
        item_id: item.id,
        type: (bid && bid.id) ? 'edit' : 'create',
        first_bid: !item.bidderIds.includes(user.id),
        context: config.context,
      });
    }
  }

  addPayment = (type: number, pane: string) => {
    this.setState({ paymentType: type });
    this.changePane(pane);
  }

  changePane = (pane: string, updates?: Object = {}, newBid?: Bid) => {
    // Reset errors when changing panes
    this.setState({ currentPane: pane, updates: updates });
    this.props.updateBidNew({
      errors: null,
      amountErrors: [],
      maxErrors: [],
      fulfillmentErrors: newBid && newBid.fulfillmentErrors ? newBid.fulfillmentErrors : [],
      confirmationError: false,
    });
  }

  isNotInternationalShippingEligible(
    address: Address,
    item: Item,
    newBid: {
      fulfillmentErrors: Array<string>,
    },
  ) {
    const ineligible = this.props.featureFlags && this.props.featureFlags.internationalShippingQuotes && address.international && !item.internationalShippingEligible;
    if (ineligible) {
      newBid.fulfillmentErrors.push('This item is not eligible for international shipping.');
    }
    return ineligible;
  }

  valueIs10xRequired = (value: ?number, requiredValue: ?number) => {
    return !!(value && requiredValue && value >= (requiredValue * 10) && value > 1000);
  }

  handleBidChange = (changes: BidChange): void => {
    const {
      item,
      updateBidNew,
      bidNew,
      user,
    } = this.props;
    const required = nextBidMinimum(item.highBidAmount, item.minimumBidAmount);
    const amountIs10x = this.valueIs10xRequired(changes.amount, required);
    // If bid or max goes over 10x the required amount, and is more than $1,000,
    // make the user re-check the confirmation checkbox.

    const changesCopy = { ...changes };
    const potentialMisbidHeading = 'Potential Misbid';

    if (amountIs10x) {
      changesCopy.errors = {
        heading: potentialMisbidHeading,
        message: ['This looks like a potential misbid, as the amount is significantly higher than the current bid. Consider using a Max Bid for this amount.'],
      };
    } else if (changesCopy.errors && changesCopy.errors.heading === potentialMisbidHeading) {
      changesCopy.errors = null;
    }
    if (bidNew.address && bidNew.address.id) {
      const address = userAddressIdToObject(bidNew.address.id, user);
      if (address) {
        changesCopy.fulfillmentErrors = [];
        this.isNotInternationalShippingEligible(address, item, changesCopy);
      }
    }
    updateBidNew({ ...changesCopy });
  }

  handleSubmitBid = async () => {
    const {
      bidNew,
      item,
      config,
      user,
    } = this.props;
    const [hasErrors, errors] = validateBid(bidNew, item);
    const requiredIncrement = bidIncrement(item.highBidAmount);
    const requiredAmount = nextBidMinimum(item.highBidAmount, item.minimumBidAmount);
    const amountIs10x = this.valueIs10xRequired(bidNew.amount, requiredAmount);

    this.handleBidChange({ ...errors });

    if (hasErrors) { return; }

    this.setState({ bidInFlight: true });

    const {
      id,
      amount,
      fulfillmentType,
      paymentMethod,
      max,
      address,
      shippingQuote,
      hasPaymentDiscount,
      bypassGiftCardBalance,
    } = bidNew;

    // eslint-disable-next-line no-alert
    if (!id && amountIs10x && !window.confirm(`Proceed with this bid amount?\n\n${formatCurrency(bidNew.amount)}`)) {
      this.setState({ bidInFlight: false });
      return;
    }

    const addressDetails = address ? userAddressIdToObject(address.id, user) : null;

    let response;

    const bidParams = {
      itemId: item.id,
      fulfillmentTypeId: fulfillmentType && fulfillmentType.id,
      paymentMethodId: paymentMethod && paymentMethod.id,
      hasPaymentDiscount: hasPaymentDiscount,
      addressId: address && address.id,
      shippingQuoteId: shippingQuote && shippingQuote.id,
      bypassGiftCardBalance: bypassGiftCardBalance,
    };

    const analyticsEvent = id ? 'Item Bid Edited' : 'Item Bid';
    const analyticsParams = {
      item_id: item.id,
      item_end_time: item.saleEndsAt,
      bid_amount: amount,
      max_bid_amount: max,
      context: config.context,
      has_edited_max_bid: (null: ?boolean),
      has_edited_fulfillment: (null: ?boolean),
      has_edited_payment: (null: ?boolean),
      has_edited_address: (null: ?boolean),
      is_auto_outbid: (null: ?boolean),
      required_increment_amount: (null: ?number),
      required_increment: (null: ?number),
      bid_id: (null: ?string),
      ...(fulfillmentType && {
        fulfillment_type_id: fulfillmentType.id,
        fulfillment_cost: fulfillmentType.price,
      }),
      ...(paymentMethod && {
        is_primary_payment: paymentMethod.isPrimary,
        payment_method_type_id: paymentMethod.paymentMethodTypeId,
      }),
      ...(addressDetails && {
        is_primary_address: addressDetails.isPrimary,
        address_id: addressDetails.id,
      }),
    };

    try {
      if (id) {
        response = await updateBid({
          ...bidParams,
          id,
          max: max || amount,
        });

        analyticsParams.has_edited_max_bid = max != null && this.props.bid.max !== max;
        analyticsParams.has_edited_fulfillment = (
          (this.props.bid.fulfillmentType && this.props.bid.fulfillmentType.id) !==
          (fulfillmentType && fulfillmentType.id)
        );
        analyticsParams.has_edited_payment = (
          (this.props.bid.paymentMethod && this.props.bid.paymentMethod.id) !==
          (paymentMethod && paymentMethod.id)
        );
        analyticsParams.has_edited_address = (
          (this.props.bid.address && this.props.bid.address.id) !==
          (addressDetails && addressDetails.id)
        );
        analyticsParams.bid_id = id;
      } else {
        response = await placeBid({
          ...bidParams,
          amount: amount || 0,
          max,
        });

        analyticsParams.is_auto_outbid = false;
        analyticsParams.required_increment_amount = requiredIncrement;

        if (response && response.bid && response.bid.id) {
          analyticsParams.bid_id = response.bid.id;
        }
      }

      this.setState({ bidInFlight: false });
      this.props.closeModal('bidModal');

      let bidUpdate = {
        fulfillmentType: bidNew.fulfillmentType,
        paymentMethod: bidNew.paymentMethod,
        hasPaymentDiscount: bidNew.hasPaymentDiscount,
        address: bidNew.address,
        shippingQuote: bidNew.shippingQuote,
      };

      if (response && response.bid) {
        bidUpdate = {
          ...bidUpdate,
          id: String(response.bid.id),
          amount: response.bid.amount,
          max: response.bid.max,
          bypassGiftCardBalance: response.bid.bypassGiftCardBalance,
        };
      }

      this.props.updateBid(bidUpdate);

      if (window.analytics) {
        window.analytics.track(analyticsEvent, analyticsParams);
      }

      if (response && response.message) {
        // request was successful,
        // so we set the flash message body and reload the page
        setPublicMessageInStorage(response.message);
        window.location.reload(true);
      }
    } catch (error) {
      if (error.type === 'outbid') {
        analyticsParams.required_increment = requiredIncrement;
        analyticsParams.bid_id = 'N/A';
        analyticsParams.is_auto_outbid = true;
        if (window.analytics) {
          window.analytics.track(analyticsEvent, analyticsParams);
        }
        this.props.updateBid({
          fulfillmentType: bidNew.fulfillmentType,
          paymentMethod: bidNew.paymentMethod,
          hasPaymentDiscount: bidNew.hasPaymentDiscount,
          address: bidNew.address,
          shippingQuote: bidNew.shippingQuote,
        });
      }
      this.handleBidChange({ errors: error });
      this.setState({ bidInFlight: false });
      if (error) {
        // request was not successful,
        // so we set the flash message body and reload the page
        const message = error.message ? error.message : 'An unknown error occurred';
        setPublicMessageInStorage(`BID NOT ACCEPTED: ${message}`, 'error');
        window.location.reload(true);
      }
    }
  }

  handleAddressChange = (a) => {
    const {
      bidNew,
      resetQuote,
      fetchQuote,
      item,
      updateBidNew,
      featureFlags,
    } = this.props;

    if (bidNew.address && (bidNew.address.id !== a.id)) {
      resetQuote();
      if (!this.isNotInternationalShippingEligible(a, item, bidNew)) {
        if (!a.international) {
          fetchQuote({ zip: a.zip, itemId: item.id });
        } else if (featureFlags.internationalShippingQuotes) {
          fetchQuote({ addressId: a.id, itemId: item.id });
        }
      }
      const shippingFulfillment = item.fulfillmentTypes.find((ft) => (ft.type === 'shipping'));
      updateBidNew({ address: a, fulfillmentType: shippingFulfillment });
    }
    this.changePane('fulfillment', {}, bidNew);
  }

  handlePaymentChange = (pm: PaymentMethod) => {
    this.props.updateBidNew({ paymentMethod: pm });
    this.changePane('bid');
  }

  setDidUserFocusBidAmount = (value: boolean) => {
    this.setState({ didUserFocusBidAmount: value });
  }

  setIsMaxBid = (value: boolean) => {
    this.setState({ isMaxBid: value });
  }

  render() {
    const {
      item,
      user,
      bidNew,
      config,
    } = this.props;
    const {
      bidInFlight, currentPane, paymentType, updates, didUserFocusBidAmount, isMaxBid,
    } = this.state;
    const addresses = user.addresses || [];

    if (currentPane === 'fulfillment') {
      return (
        <PaneSelectFulfillment
          onBidChange={this.handleBidChange}
          bid={bidNew}
          item={item}
          changePane={this.changePane}
          config={config}
          user={user}
        />
      );
    } else if (currentPane === 'address') {
      return (
        <PaneSelectAddress
          user={user}
          bid={bidNew}
          changePane={this.changePane}
          onAddressChange={this.handleAddressChange}
        />
      );
    } else if (currentPane === 'bid') {
      return (
        <PanePlaceBid
          onFormSubmit={this.handleSubmitBid}
          onBidChange={this.handleBidChange}
          bid={bidNew}
          changePane={this.changePane}
          newBidErrors={bidNew.errors}
          bidInFlight={bidInFlight}
          didUserFocusBidAmount={didUserFocusBidAmount}
          setDidUserFocusBidAmount={this.setDidUserFocusBidAmount}
          isMaxBid={isMaxBid}
          setIsMaxBid={this.setIsMaxBid}
        />
      );
    } else if (currentPane === 'payment') {
      return (
        <PaneSelectPayment
          bid={bidNew}
          changePane={this.changePane}
          addPayment={this.addPayment}
          bustCache={updates.paymentAdded}
          user={user}
          onPaymentChange={this.handlePaymentChange}
          onBidChange={this.handleBidChange}
        />
      );
    } else if (currentPane === 'addpayment') {
      return (
        <PaneAddPaymentContainer
          user={user}
          addresses={addresses}
          paymentType={paymentType}
          bid={bidNew}
          changePane={this.changePane}
          onPaymentChange={this.handlePaymentChange}
          {...this.props}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (
  {
    item: { item },
    bid,
    bidNew,
    user: { user },
    config,
    shippingQuoteBid,
  }
) => ({
  item,
  bid,
  bidNew,
  user,
  config,
  shippingQuoteBid,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      ...ModalActions,
      ...BidActions,
      ...BidNewActions,
      ...ShippingQuoteBidActions,
    },
    dispatch
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(ModalBiddingContent);
