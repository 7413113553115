export const PUBLIC_FLASH_MESSAGE_STORAGE_NAME = 'EBTHpublicFlashMessage';
export const PUBLIC_FLASH_TYPE_STORAGE_NAME = 'EBTHpublicFlashType';

export const flashPublicMessage = (message, type) => {
  window.EBTH.flash.show(message, type);
};

export const flashPublicMessageFromStorage = () => {
  const message = sessionStorage.getItem(PUBLIC_FLASH_MESSAGE_STORAGE_NAME);
  const type = sessionStorage.getItem(PUBLIC_FLASH_TYPE_STORAGE_NAME);
  if (message) {
    flashPublicMessage(message, type);
    sessionStorage.removeItem(PUBLIC_FLASH_MESSAGE_STORAGE_NAME);
    sessionStorage.removeItem(PUBLIC_FLASH_TYPE_STORAGE_NAME);
  }
};

export const setPublicMessageInStorage = (message, type) => {
  sessionStorage.setItem(PUBLIC_FLASH_MESSAGE_STORAGE_NAME, message);
  sessionStorage.setItem(PUBLIC_FLASH_TYPE_STORAGE_NAME, type);
};
